import styled from 'styled-components';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Flexbox } from '@/components/flex';
import { INSPECTOR_BACK_ID } from '@/features/editor/inspector';
import { useIsPreviewingDesktop } from '@/features/editor/shared/use-device-type';
import { InspectorWidgetLayout } from '@/features/editor/widgets/shared/layout';
import {
  EditorDeclarativeBlock,
  PlacementKind,
} from '@/webapi/use-experience-api';
import { EditorContext } from '@/features/editor/context/editor-context';
import { StylingSection } from '@/features/editor/widgets/custom-widget/blueprint-customize';
import { LinkInput } from '@/features/editor/widgets/custom-widget/inputs/shared/link';
import { DeviceType } from '@/utils/definitions';
import { RedirectSourceUrlModal } from '@/features/editor/widgets/page-redirect/source-url-modal';
import { QBItemSelection } from '@/components/query-builder/models';
import { useComplexState } from '@/utils/use-complex-state';
import { FormattedSourceUrl } from '@/features/editor/widgets/page-redirect/formatted-source-url';
import { usePlacementQueryBuilder } from '@/features/editor/widgets/shared/modals/use-placement-query-builder';
import { nav } from '@/utils/browser';
import { Pages } from '@/webapi/pages';
import { EditorRenameButton } from '@/features/editor/widgets/shared/layout/editor-rename-button';
import { DescriptionEditButton } from '@/features/editor/widgets/shared/layout/descr-rename-button';
import { TitleWrapper } from '@/features/editor/widgets/changelog';
import { GeneralText } from '@/features/editor/widgets/shared/general-text';
import { VSpace } from '@/components/spacing';
import { TargetingControlsList } from '@/features/editor/widgets/shared/targeting-controls/targeting-controls-list';
import { useWidgetControls } from '@/features/editor/widgets/shared/use-widget-controls';
import { PageRedirectChange } from '@/pkg/sdk';

export interface PageRedirectWidgetProps {
  originalChange: EditorDeclarativeBlock;
}

export function PageRedirectWidget({
  originalChange,
}: PageRedirectWidgetProps) {
  const {
    resources,
    inspectorNav,
    deviceNavigation,
    devicePreview,
    experienceState: {
      setTargetingPlacement,
      currentExperience,
      upsertEditorChange,
    },
  } = useContext(EditorContext);
  const { pageUrl } = usePlacementQueryBuilder(
    resources,
    currentExperience.experienceCreationVersion,
  );
  const sourceUrlRef = useRef(null);
  const isDesk = useIsPreviewingDesktop();
  const [isSourceModalVisible, setIsSourceModalVisible] = useState(false);

  const [newChange, setNewChange] = useComplexState(originalChange);

  const onNext = () => {
    inspectorNav.gotoTargeting({
      disablePlacementPicker: true,
      disableThemesPicker: true,
    });
  };

  const { WidgetControls, controlsProps } = useWidgetControls(onNext, `Next`);

  const onTargetUrlInitialValue = (_1: string, _2: DeviceType) =>
    newChange?.pageRedirectOpts?.targetUrl || ``;

  const onValuesChanged = (_1: string, value: string, _2: DeviceType) => {
    setNewChange((draft) => {
      draft.pageRedirectOpts = {
        ...draft.pageRedirectOpts,
        targetUrl: cleanUrl(value),
      };
      draft.block.value = {
        destUrl: cleanUrl(value),
        redirectAfter: 1,
      } as PageRedirectChange;
    });
  };

  const onSourceUrlSelected = (rules: Array<QBItemSelection>) => {
    setNewChange((draft) => {
      draft.pageRedirectOpts = {
        ...draft.pageRedirectOpts,
        sourceUrlProps: rules,
      };
    });
  };

  useEffect(() => {
    devicePreview.iframeRef.current.style.pointerEvents = `none`;
  }, []);

  useEffect(() => {
    if (
      newChange?.pageRedirectOpts?.targetUrl &&
      newChange?.pageRedirectOpts?.sourceUrlProps
    ) {
      const newTargeting = {
        kind: PlacementKind.OTHER,
        other: [
          ...newChange.pageRedirectOpts.sourceUrlProps,
          {
            qbProps: pageUrl,
            values: [
              {
                op: `isnot`,
                value: newChange?.pageRedirectOpts?.targetUrl,
              },
            ],
          },
        ],
      };
      setTargetingPlacement(newTargeting, true);
      upsertEditorChange(newChange);
      setTimeout(() => setTargetingPlacement(newTargeting, true), 500);
      setTimeout(
        () =>
          deviceNavigation.navigateTo(newChange?.pageRedirectOpts?.targetUrl),
        1000,
      );
    }
  }, [currentExperience, newChange]);

  return (
    <Wrapper isDesk={isDesk}>
      <RedirectSourceUrlModal
        newChange={newChange}
        onSave={onSourceUrlSelected}
        fromRef={sourceUrlRef}
        isVisible={isSourceModalVisible}
        setIsVisible={setIsSourceModalVisible}
      />
      <InspectorWidgetLayout
        progress={75}
        title={
          <TitleWrapper>
            <EditorRenameButton />
            <DescriptionEditButton />
          </TitleWrapper>
        }
        backCaption="< back to dashboard"
        onBackClicked={() => nav(Pages.DASHBOARD)}
        footer={<WidgetControls {...controlsProps} showHistory={false} />}
      >
        <GeneralText>
          1. What audience and where will the experience run?
        </GeneralText>
        <VSpace value={2} />
        <TargetingControlsList disablePlacementPicker />
        <VSpace value={3} />
        <GeneralText>2. Which redirection do you want to setup?</GeneralText>
        <Section ref={sourceUrlRef}>
          <h3>Source URL</h3>
          <div onClick={() => setIsSourceModalVisible(true)}>
            {newChange?.pageRedirectOpts?.sourceUrlProps?.length > 0 ? (
              <DummyInput>
                <FormattedSourceUrl
                  sourceUrlProps={newChange.pageRedirectOpts?.sourceUrlProps}
                />
              </DummyInput>
            ) : (
              <LinkInput
                customizationIdx={1}
                customization={undefined}
                componentIdx={1}
                component={undefined}
                specIdx={1}
                spec={undefined}
                device={isDesk ? DeviceType.Desktop : DeviceType.Mobile}
                initialValues={() => ``}
                onValuesChanged={onValuesChanged}
              />
            )}
          </div>
          <h3>Destination URL</h3>
          <LinkInput
            customizationIdx={1}
            customization={undefined}
            componentIdx={1}
            component={undefined}
            specIdx={1}
            spec={undefined}
            device={isDesk ? DeviceType.Desktop : DeviceType.Mobile}
            initialValues={onTargetUrlInitialValue}
            onValuesChanged={onValuesChanged}
          />

          <p>
            * Note that customers who reach the source URL will automatically be
            redirected to the destination URL, so we will override & disable the
            general page targeting of the experiment
          </p>
        </Section>
      </InspectorWidgetLayout>
    </Wrapper>
  );
}

const Wrapper = styled(Flexbox)`
  && {
    height: 100%;
    width: ${(p: any) => (p.isDesk ? `100%` : `65%`)};

    #${INSPECTOR_BACK_ID} {
      z-index: 31;
    }

    div.input-link {
      cursor: pointer;
      grid-template-columns: minmax(0, 1fr) 4rem;

      .prefix {
        display: none;
      }
    }

    div.input-link:first-child .content {
      pointer-events: none !important;
    }
  }
`;

const Section = styled(StylingSection)`
  && {
    text-align: start;
    padding: 2rem;
    gap: 1.5rem;

    && > h3 {
      padding: 0;
      margin: 0;
      font-family: 'Inter', sans-serif;
      font-weight: 600;
      color: #798794;
      font-size: 1.4rem;
    }

    && > p {
      padding: 0;
      margin: 0;
      font-family: 'Inter', sans-serif;
      color: #798794;
      font-weight: 300;
      font-size: 1.1rem;
      line-height: 1.8;
      letter-spacing: 0.5px;
    }
  }
`;

const DummyInput = styled.div`
  width: 100%;
  max-width: 100%;
  cursor: pointer;
  color: #5b656e;
  font-family: 'JetBrains Mono', serif;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: -0.03px;
  transform: translate(0, 0);
  background-color: #ffffff;
  box-shadow: 0 18px 26px 0 rgba(177, 217, 203, 0.18);
  padding: 0.8rem 1.8rem;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: stretch;
  white-space: nowrap;
`;

function cleanUrl(url: string) {
  return url.trim();
}
