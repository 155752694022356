import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Popover } from 'react-tiny-popover';
import { NumberInput } from '@/features/editor/widgets/custom-widget/inputs/shared/number-input';
import { NumberInputImage } from '@/features/editor/widgets/custom-widget/inputs/shared/number-input-image';
import { LinkInputPopover } from '@/features/editor/widgets/custom-widget/inputs/shared/link/link-popover';
import { ColorInputRichTextVsly } from '@/features/editor/widgets/custom-widget/inputs/shared/vsly-color-rich-text';
import { Control } from '@/features/editor/widgets/custom-widget/inputs/decoration/shared';
import { DeviceType } from '@/utils/definitions';
import { CheckboxInput } from '@/features/editor/widgets/custom-widget/inputs/shared/checkbox-input';
import { CloseButton } from '@/components/close-button';

const lineHeightSrc =
  require(`../../../../../../assets/line-height.svg`).default;

const ReactQuill =
  typeof window === `object` ? require(`react-quill`) : () => false;

export interface RichTextProps {
  updatedFonts: any[];
  isRendered: boolean;
  setIsRendered: (val: boolean) => void;
  colorValue: string;
  setColorValue: (val: string) => void;
  textShadowValue: string;
  fontSizeValue: string;
  setFontSizeValue: (val: string) => void;
  letterSpacingValue: string;
  setLetterSpacingValue: (val: string) => void;
  lineHeightValue: string;
  setLineHeightValue: (val: string) => void;
  editorRef: any;
  spec?: any;
}

export function CustomToolbarRichText({
  isRendered,
  setIsRendered,
  updatedFonts,
  colorValue,
  setColorValue,
  textShadowValue,
  fontSizeValue,
  setFontSizeValue,
  letterSpacingValue,
  setLetterSpacingValue,
  lineHeightValue,
  setLineHeightValue,
  editorRef,
}: RichTextProps) {
  const { Quill } = ReactQuill;
  const Parchment = Quill.import(`parchment`);

  const debounceTimeout = useRef(null);

  const [isLinkMenuOpen, setIsLinkMenuOpen] = useState(false);
  const [link, setLink] = useState(``);

  const [isShadowMenuOpen, setIsShadowMenuOpen] = useState(false);
  const [isTextShadowEnabled, setIsTextShadowEnabled] = useState(false);
  const [shadowColor, setShadowColor] = useState(`rgb(0, 0, 0)`);
  const [x, setX] = useState(`1px`);
  const [y, setY] = useState(`1px`);
  const [blur, setBlur] = useState(`1px`);

  useEffect(() => {
    const registerStyle = (name, attribute, config) => {
      const style = new Parchment.Attributor.Style(name, attribute, config);
      Quill.register(style, true);
    };

    // Configurations
    const blockScope = { scope: Parchment.Scope.BLOCK, whitelist: null };
    const inlineScope = { scope: Parchment.Scope.INLINE, whitelist: null };

    // Register styles
    registerStyle(`transform`, `text-transform`, {
      ...blockScope,
      whitelist: [`normal`, `uppercase`, `lowercase`],
    });
    registerStyle(`fontWeight`, `font-weight`, inlineScope);
    registerStyle(`letterSpacing`, `letter-spacing`, inlineScope);
    registerStyle(`lineHeight`, `line-height`, blockScope);
    registerStyle(`textShadow`, `text-shadow`, inlineScope);

    // Register existing Quill styles
    [`color`, `align`, `direction`, `size`, `font`].forEach((styleName) => {
      const style = Quill.import(`attributors/style/${styleName}`);
      if (styleName === `size` || styleName === `font`) style.whitelist = null;
      if (styleName === `font`) style.scope = blockScope.scope;
      Quill.register(style, true);
    });
  }, [isRendered]);

  function onValueChange(setValue, value, format, delay = 1000) {
    if (setValue) setValue(value);
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      if (editorRef?.current) {
        const editor = editorRef.current.getEditor();
        if (editor) {
          editor.format(format, value);
        }
      }
    }, delay);
  }

  useEffect(() => {
    const array = textShadowValue?.replaceAll(`, `, `,`).split(` `);
    if (array?.length === 4) {
      setX(array[1].replaceAll(`.00`, ``) || `1px`);
      setY(array[2].replaceAll(`.00`, ``) || `1px`);
      setBlur(array[3].replaceAll(`.00`, ``) || `1px`);
      setShadowColor(array[0].replaceAll(`,`, `, `) || `rgb(0, 0, 0)`);
    } else {
      setShadowColor(`rgb(0, 0, 0)`);
      setX(`1px`);
      setY(`1px`);
      setBlur(`1px`);
    }
  }, [textShadowValue]);

  useEffect(() => {
    if (!isShadowMenuOpen) {
      setIsTextShadowEnabled(false);
      return;
    }
    if (!isTextShadowEnabled) {
      onValueChange(null, `unset`, `textShadow`, 500);
    } else {
      const value = `${shadowColor || `rgb(0, 0, 0)`} ${
        x.replaceAll(`.00`, ``) || `0px`
      } ${y.replaceAll(`.00`, ``) || `0px`} ${
        blur.replaceAll(`.00`, ``) || `0px`
      }`;
      onValueChange(null, value, `textShadow`, 500);
    }
  }, [isShadowMenuOpen, isTextShadowEnabled, shadowColor, x, y, blur]);

  const containerRef = useRef<HTMLElement>(null);

  const getParentRect = useCallback(() => {
    if (containerRef) {
      return containerRef?.current?.getBoundingClientRect();
    }
    return {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      height: 0,
      width: 0,
    } as DOMRect;
  }, [containerRef]);

  useEffect(() => {
    setIsRendered(true);
  }, []);

  return (
    <Wrapper id="toolbar">
      <InputWrapper widthValue="calc(100% -  3 * (60px + 1rem))">
        <FontFamilySelect className="ql-font" aria-label="font">
          {updatedFonts.map((option) => (
            <option style={{ fontFamily: option }} key={option} value={option}>
              {option}
            </option>
          ))}
        </FontFamilySelect>
      </InputWrapper>
      <InputWrapper widthValue="60px">
        <NumberInput
          min={1}
          aria-label="FontSize"
          max={128}
          suffix="px"
          onChange={(v) => onValueChange(setFontSizeValue, v, `size`)}
          defaultValue={
            Array.isArray(fontSizeValue) ? fontSizeValue[0] : fontSizeValue
          }
        />
      </InputWrapper>
      <InputWrapper widthValue="60px">
        <NumberInput
          min={-100}
          aria-label="LetterSpacing"
          max={100}
          step={0.1}
          suffix="px"
          onChange={(v) =>
            onValueChange(setLetterSpacingValue, v, `letterSpacing`)
          }
          defaultValue={
            Array.isArray(letterSpacingValue)
              ? letterSpacingValue[0]
              : letterSpacingValue
          }
        />
      </InputWrapper>
      <InputWrapper widthValue="60px">
        <ColorInputRichTextVsly
          onChange={(v) => onValueChange(setColorValue, v, `color`, 0)}
          value={Array.isArray(colorValue) ? colorValue[0] : colorValue}
        />
      </InputWrapper>
      <InputWrapper widthValue="calc(100% - 60px - 60px - 105px - 3rem)">
        <FontWeightSelect
          className="ql-fontWeight"
          aria-label="fontWeight"
          defaultValue="400"
        >
          {fontWeightOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </FontWeightSelect>
      </InputWrapper>
      <InputWrapper widthValue="105px">
        <LineHeightWrapper>
          <NumberInputImage
            min={1}
            aria-label="LineHeight"
            max={128}
            suffix="px"
            Image={LineHeightIcon}
            onChange={(v) =>
              onValueChange(
                setLineHeightValue,
                v.includes(`NaN`) && isRendered
                  ? `${Math.round(
                      parseInt(fontSizeValue, 10) * 1.4,
                    ).toString()}px`
                  : v,
                `lineHeight`,
              )
            }
            defaultValue={
              Array.isArray(lineHeightValue)
                ? lineHeightValue[0]
                : lineHeightValue
            }
          />
        </LineHeightWrapper>
      </InputWrapper>
      <InputWrapper widthValue="60px">
        <TextTransformSelect
          className="ql-transform"
          aria-label="transform"
          defaultValue="normal"
        >
          {transformOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextTransformSelect>
      </InputWrapper>
      <InputWrapper widthValue="60px">
        <Popover
          isOpen={isLinkMenuOpen}
          content={
            <LinkInputPopover
              defaultValue={link}
              width={getParentRect()?.width}
              onClose={() => setIsLinkMenuOpen(false)}
              onSelection={(v) => {
                setIsLinkMenuOpen(false);
                onValueChange(setLink, v, `link`, 0);
              }}
            />
          }
          onClickOutside={() => {
            setIsLinkMenuOpen(false);
          }}
          positions={[`bottom`]}
          contentLocation={getParentRect()}
          containerStyle={{ width: `450px`, zIndex: `100`, top: `-285px` }}
        >
          <ContentLink
            className="content"
            onClick={() => setIsLinkMenuOpen(true)}
          >
            <LinkIcon />
          </ContentLink>
        </Popover>
      </InputWrapper>
      <ButtonsWrapper>
        <Popover
          isOpen={isShadowMenuOpen}
          content={
            <TextShadowWrapper>
              <TextShadowHeader>
                <CheckboxInput
                  defaultValue={isTextShadowEnabled}
                  onChange={setIsTextShadowEnabled}
                >
                  Add Text Shadow
                </CheckboxInput>
                <ColorInputRichTextVsly
                  onChange={setShadowColor}
                  value={shadowColor}
                  debounceDelay={1000}
                />
                <CloseButton
                  style={{
                    position: `absolute`,
                    top: 0,
                    right: 0,
                    width: `25px`,
                  }}
                  onClick={() => {
                    setIsShadowMenuOpen(false);
                  }}
                />
              </TextShadowHeader>
              <ControlsWrapper>
                <Control
                  caption="X"
                  envKey="right"
                  initialValues={(
                    _key?: string,
                    _device?: DeviceType,
                    _specIdx?: number,
                  ) => x}
                  onChange={(value) => setX(value)}
                />
                <Control
                  caption="Y"
                  envKey="bottom"
                  initialValues={(
                    _key?: string,
                    _device?: DeviceType,
                    _specIdx?: number,
                  ) => y}
                  onChange={(value) => setY(value)}
                />
                <Control
                  caption="Blur"
                  envKey="blur"
                  initialValues={(
                    _key?: string,
                    _device?: DeviceType,
                    _specIdx?: number,
                  ) => blur}
                  onChange={(value) => setBlur(value)}
                />
              </ControlsWrapper>
            </TextShadowWrapper>
          }
          positions={[`top`]}
          contentLocation={getParentRect()}
          containerStyle={{
            width: `400px`,
            zIndex: `100`,
            top: `-10px`,
            left: `160px`,
          }}
        >
          <ContentShadow
            className="content"
            onClick={() => {
              setIsShadowMenuOpen(true);
              setIsTextShadowEnabled(true);
            }}
          >
            <TextShadowIcon />
          </ContentShadow>
        </Popover>
        <ItalicButton className="ql-italic" type="button" aria-label="Italic" />
        <UnderlineButton
          className="ql-underline"
          type="button"
          aria-label="Underline"
        />
        <StrikeButton className="ql-strike" type="button" aria-label="Strike" />

        <ListButton
          className="ql-list"
          type="button"
          value="ordered"
          aria-label="Ordered list"
        />
        <ListButton
          className="ql-list"
          type="button"
          value="bullet"
          aria-label="Bullet list"
        />

        <DirectionButton
          className="ql-direction"
          type="button"
          value="rtl"
          aria-label="Right to Left"
        />
        <AlignSelect defaultValue="" className="ql-align" aria-label="Align">
          {alignOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </AlignSelect>
      </ButtonsWrapper>
      <UndoRedoWrapper>
        <UndoButton className="ql-undo">
          <CustomUndo />
        </UndoButton>
        <RedoButton className="ql-redo">
          <CustomRedo />
        </RedoButton>
      </UndoRedoWrapper>
    </Wrapper>
  );
}

function undoChange() {
  this.quill?.history?.undo();
}

function redoChange() {
  this.quill?.history?.redo();
}

export const modules = {
  toolbar: {
    container: `#toolbar`,
    handlers: {
      undo: undoChange,
      redo: redoChange,
    },
  },
  history: {
    delay: 500,
    maxStack: 100,
  },
};

const CustomUndo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
    <path
      className="ql-stroke"
      d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"
    />
  </svg>
);

const CustomRedo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
    <path
      className="ql-stroke"
      d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"
    />
  </svg>
);

const LineHeightIcon = () => (
  <img
    src={lineHeightSrc}
    alt="line height"
    height={16}
    placeholder="none"
    loading="eager"
  />
);

const LinkIcon = () => (
  <svg viewBox="0 0 18 18">
    <line className="ql-stroke" x1="7" x2="11" y1="7" y2="11" />
    <path
      className="ql-even ql-stroke"
      d="M8.9,4.577a3.476,3.476,0,0,1,.36,4.679A3.476,3.476,0,0,1,4.577,8.9C3.185,7.5,2.035,6.4,4.217,4.217S7.5,3.185,8.9,4.577Z"
    />
    <path
      className="ql-even ql-stroke"
      d="M13.423,9.1a3.476,3.476,0,0,0-4.679-.36,3.476,3.476,0,0,0,.36,4.679c1.392,1.392,2.5,2.542,4.679.36S14.815,10.5,13.423,9.1Z"
    />
  </svg>
);

const TextShadowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="15px"
    height="16px"
    viewBox="0 0 15 16"
    version="1.1"
  >
    <title>Group 2</title>
    <defs>
      <filter
        x="0.0%"
        y="0.0%"
        width="100.0%"
        height="100.0%"
        filterUnits="objectBoundingBox"
        id="filter-1"
      >
        <feGaussianBlur stdDeviation="0" in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Artboard"
        transform="translate(-128, -265)"
        fill="#414141"
        fillRule="nonzero"
      >
        <g id="Group-2" transform="translate(128.4916, 265.4386)">
          <g id="A">
            <path
              d="M0,12.4976 L3.14259,0 L5.90533,0 L9.06076,12.4976 L6.8819725,12.4976 L6.1902175,9.466825 L2.8774975,9.466825 L2.18601,12.4976 L0,12.4976 Z M3.2557425,7.724865 L5.7948525,7.724865 L5.046655,4.3637275 C4.92414,3.8067925 4.81651583,3.30130667 4.7237825,2.84727 C4.63104917,2.39323333 4.56658167,2.0653675 4.53038,1.8636725 C4.49417833,2.0653675 4.43305458,2.39158375 4.34700875,2.84232125 C4.26096292,3.29305875 4.15338333,3.7944875 4.02427,4.3466075 L3.2557425,7.724865 Z"
              id="Shape"
            />
          </g>
          <g
            id="A-Copy"
            transform="translate(5.0504, 2.1276)"
            opacity="0.197969"
          >
            <path
              d="M0,12.4976 L3.06448,0 L6.31728,0 L9.416,12.4976 L6.79664,12.4976 L6.19744,9.6728 L3.21856,9.6728 L2.61936,12.4976 L0,12.4976 Z M3.64656,7.6184 L5.75232,7.6184 L5.15312,4.60528 C5.07322667,4.17157333 4.99048,3.74072 4.90488,3.31272 C4.81928,2.88472 4.75365333,2.55088 4.708,2.3112 C4.66234667,2.55088 4.60242667,2.88186667 4.52824,3.30416 C4.45405333,3.72645333 4.37130667,4.15445333 4.28,4.58816 L3.64656,7.6184 Z"
              id="Shape"
              filter="url(#filter-1)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const transformOptions = [
  { value: `normal`, label: `Aa` },
  { value: `uppercase`, label: `AA` },
  { value: `lowercase`, label: `aa` },
];

const alignOptions = [
  { value: ``, label: `Left` },
  { value: `center`, label: `Center` },
  { value: `right`, label: `Right` },
];

const fontWeightOptions = [
  { label: `Thin`, value: `100` },
  { label: `Ultra Light`, value: `200` },
  { label: `Light`, value: `300` },
  { label: `Normal`, value: `` },
  { label: `Medium`, value: `500` },
  { label: `Semi Bold`, value: `600` },
  { label: `Bold`, value: `700` },
  { label: `Ultra Bold`, value: `800` },
  { label: `Heavy`, value: `900` },
];

const Wrapper = styled.div`
  width: 100%;
  max-width: 100%;
  border: none !important;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
  padding: 12px !important;

  && input {
    scale: 90%;
  }
`;
const ButtonsWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;
const UndoRedoWrapper = styled.div`
  display: flex;
  margin-left: auto;
`;
const TextTransformSelect = styled.select`
  margin-right: 10px;
  width: 65px;

  .ql-picker-item:before {
    content: attr(data-label);
  }

  .ql-picker-label:before {
    content: attr(data-label);
  }
`;
const FontWeightSelect = styled.select`
  margin-right: 10px;
  width: 100px;

  .ql-picker-item:before {
    content: attr(data-label);
  }

  .ql-picker-label:before {
    content: attr(data-label);
  }
`;

const ItalicButton = styled.button``;

const UnderlineButton = styled.button``;

const StrikeButton = styled.button``;

const ListButton = styled.button``;

const DirectionButton = styled.button``;

const AlignSelect = styled.select``;

const UndoButton = styled.button``;

const RedoButton = styled.button``;

const FontFamilySelect = styled.select``;

const InputWrapper = styled.div`
  width: ${(props: { width?: number; widthValue?: string }) => {
    if (props.widthValue) {
      return props.widthValue;
    }
    if (props.width) {
      return `calc(100% * ${props.width} - 1rem)`;
    }
    return `auto`;
  }};
  border-radius: 10px;
  border: 1px #ddd solid;
`;

const LineHeightWrapper = styled.div`
  && > div {
    gap: 0 !important;
  }

  && input {
    padding-right: 5px !important;
  }
`;

const ContentLink = styled.div`
  width: 100%;
  cursor: pointer;
  display: flex;
  height: 100%;

  && > svg {
    max-width: 20px;
    margin: auto;
  }

  :hover {
    && * {
      stroke: #06c;
    }
  }
`;
const ContentShadow = styled.div`
  cursor: pointer;
  height: 100%;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    && * {
      stroke: #06c;
    }
  }
`;
const ControlsWrapper = styled.div`
  padding-top: 1.5rem;
  border-top: 1px #ddd solid;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.2rem;
`;
const TextShadowWrapper = styled.div`
  background: white;
  padding: 2rem;
  border: 1px #bbb solid;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.3) 0 7px 29px 0;
`;
const TextShadowHeader = styled.div`
  display: flex;
  position: relative;
  padding-bottom: 1.5rem;
  flex-direction: row;
  align-items: center;
  gap: 3rem;

  && > div {
    min-width: 60px;
  }
`;
