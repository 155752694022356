import { useContext } from 'react';
import {
  QBItemProp,
  QBItemSelection,
  QBType,
} from '@/components/query-builder/models';
import { LoadedEditorResources } from '@/features/editor/use-editor-loader';
import { SelectOptions } from '@/components/small-select';
import { Segment } from '@/webapi/use-experience-api';
import { countryCodes } from '@/utils/country-codes';
import { stateCodes } from '@/utils/state-codes';
import { AccountContext, useFeatureBit } from '@/features/account-context';
import { NumberValueOps } from '@/components/query-builder/numeric-value';
import { FeatureBit } from '@/webapi/use-auth-api';
import { currencies } from '@/utils/currencies';
import { Product } from '@/webapi/use-catalog-api';

const extractWords = (str) => str.split(/\W+/).filter(Boolean);
export function getProductValue(col: Product) {
  if (
    !areSimilar(
      col?.title?.toLowerCase() || ``,
      col?.handle?.toLowerCase() || ``,
    )
  ) {
    const wordsTitle = new Set(extractWords(col?.title?.toLowerCase()));
    const wordsHandle = new Set(extractWords(col?.handle?.toLowerCase()));
    wordsTitle.forEach((word) => {
      if (wordsHandle.has(word)) {
        wordsHandle.delete(word);
      }
    });
    const variantWords = ` [${Array.from(wordsHandle).join(` `)}]`;
    return `${col?.title}${
      Array.from(wordsHandle).length > 0 ? variantWords : ``
    }`;
  }
  return `${col.handle?.replaceAll(`-`, ` `)}`;
}

function areSimilar(str1: string, str2: string) {
  const words1 = new Set(extractWords(str1.toLowerCase()));
  const words2 = new Set(extractWords(str2.toLowerCase()));
  let sameWords = 0;
  words1.forEach((word) => {
    if (words2.has(word)) {
      sameWords += 1;
    }
  });
  if (sameWords >= 2) {
    return true;
  }
  return sameWords === 1 && words2.size <= 2 && words1.size <= 2;
}

export function useAudienceQueryBuilder(
  resources: LoadedEditorResources,
  otherTargeting?: QBItemSelection[] | undefined,
) {
  const isLoyaltyEnabled = useFeatureBit(
    FeatureBit.ENABLE_LOYALTY_TARGETING,
    false,
  );
  const variantAttrEnabled = useFeatureBit(FeatureBit.VARIANT_ATTRIBUTES);
  const subsEnabled = useFeatureBit(FeatureBit.SUBSCRIPTIONS_v1);
  const enhancedCartTargetingEnabled = useFeatureBit(
    FeatureBit.ENHANCED_CART_TARGETING,
  );
  const visitInPageClientSideEnabled = useFeatureBit(
    FeatureBit.VISIT_IN_PAGE_AUDIENCE_CLIENT_SIDE,
  );
  const { account } = useContext(AccountContext);
  const predefinedSegments: QBItemProp = {
    kind: QBType.ARRAY_VALUE,
    caption: `Predefined Audience`,
    group: `Other`,
    options: [
      { key: Segment.ABANDONED_CARTS, value: `Cart Abandoners` },
      { key: Segment.FIRST_SESSION, value: `New Visitors` },
      { key: Segment.RETURNING_VISITORS, value: `Returning Visitors` },
      {
        key: Segment.SUBSEQUENT_SESSIONS,
        value: `First session & subsequent sessions`,
      },
      { key: Segment.VIEWED_PRODUCTS, value: `Viewed Products` },
      { key: Segment.PURCHASED_RECENTLY, value: `Recently Purchased` },
      { key: Segment.PURCHASED_LAST_WEEK, value: `Purchased Last Week` },
      { key: Segment.NEVER_PURCHASED, value: `Never Purchased` },
      { key: Segment.HIGH_SPENDERS, value: `High Spenders` },
      { key: Segment.COUPON_LOVERS, value: `Coupon Lovers` },
      { key: Segment.ONE_TIMERS, value: `One Timers` },
      { key: Segment.CUSTOMERS, value: `Customers` },
      { key: Segment.GUESTS, value: `Guests` },
    ],
    envKey: `"$value" $op Segments`,
  };

  const device: QBItemProp = {
    kind: QBType.MULTI_VALUE,
    caption: `Device`,
    group: `Other`,
    options: [
      { key: `m`, value: `Mobile` },
      { key: `d`, value: `Desktop` },
    ],
    envKey: `Device $op "$value"`,
  };

  const os: QBItemProp = {
    kind: QBType.MULTI_VALUE,
    caption: `OS`,
    group: `Other`,
    options: [
      { key: `ios`, value: `IOS` },
      { key: `android`, value: `Android` },
      { key: `windows`, value: `Windows` },
      { key: `mac`, value: `Mac OS X` },
      { key: `chromeOS`, value: `Chrome OS` },
      { key: `linux`, value: `Linux` },
    ],
    envKey: `OS("$value","$op")`,
  };

  const brand: QBItemProp = {
    kind: QBType.MULTI_VALUE,
    caption: `Brand`,
    group: `Other`,
    options: [
      { key: `apple`, value: `Apple` },
      { key: `samsung`, value: `Samsung` },
      { key: `huawei`, value: `Huawei` },
      { key: `xiaomi`, value: `XiaoMi` },
      { key: `google`, value: `Google` },
      { key: `vivo`, value: `Vivo` },
      { key: `nokia`, value: `Nokia` },
      { key: `tecno`, value: `Tecno` },
      { key: `onePlus`, value: `OnePlus` },
      { key: `lg`, value: `LG` },
      { key: `infinix`, value: `Infinix` },
      { key: `asus`, value: `Asus` },
      { key: `lenovo`, value: `Lenovo` },
      { key: `motorola`, value: `Motorola` },
      { key: `htc`, value: `HTC` },
      { key: `zte`, value: `ZTE` },
    ],
    envKey: `Brand("$value","$op")`,
  };

  const browser: QBItemProp = {
    kind: QBType.MULTI_VALUE,
    caption: `Browser`,
    group: `Other`,
    options: [
      { key: `safari`, value: `Safari` },
      { key: `chrome`, value: `Chrome` },
      { key: `instagram`, value: `Instagram` },
      { key: `facebook`, value: `Facebook` },
      { key: `samsung`, value: `Samsung Internet` },
      { key: `firefox`, value: `Firefox` },
      { key: `edge`, value: `Edge` },
    ],
    envKey: `Browser("$value","$op")`,
  };
  const hasLegacyCartAmount = !!otherTargeting?.find(
    (x) =>
      x?.qbProps?.caption === `Cart Amount` && !x?.qbProps?.hasCurrencyCode,
  );
  const productsOptions = resources.products
    .sort((a, b) => {
      if (a.title < b.title) {
        return -1;
      }
      if (a.title > b.title) {
        return 1;
      }
      return 0;
    })
    .map(
      (col) =>
        ({
          key: col.handle,
          image: col?.image?.src,
          value: getProductValue(col),
        } as SelectOptions),
    );

  const collections = resources.collections
    .sort((a, b) => {
      if (a.title < b.title) {
        return -1;
      }
      if (a.title > b.title) {
        return 1;
      }
      return 0;
    })
    .map(
      (col) =>
        ({
          key: col.handle,
          value: col.title,
        } as SelectOptions),
    );

  const cart: QBItemProp[] = [
    {
      kind: QBType.NUMERIC_VALUE,
      caption: `Cart Items Count`,
      envKey: `len(Cart) $op $value`,
      group: `Behaviour`,
      defaultNumericValueOp: NumberValueOps.GT,
    },
    ...[
      hasLegacyCartAmount
        ? {
            kind: QBType.NUMERIC_VALUE,
            caption: `Cart Amount`,
            envKey: `CartTotal(Cart) $op $value`,
            group: `Behaviour`,
            defaultNumericValueOp: NumberValueOps.GT,
          }
        : {
            kind: QBType.NUMERIC_VALUE,
            hasCurrencyCode: true,
            caption: `Cart Total`,
            envKey: `Total($currency,$autoApply) $op $value`,
            group: `Behaviour`,
            defaultNumericValueOp: NumberValueOps.GT,
          },
    ],
    {
      kind: QBType.MULTI_VALUE,
      caption: `Cart Item`,
      group: `Behaviour`,
      envKey: `CartHasItem('$value')`,
      options: productsOptions,
    },
    ...(variantAttrEnabled && resources.mainAttributeOptions.length > 0
      ? [
          {
            kind: QBType.MULTI_VALUE,
            caption: `Variant In Cart`,
            group: `Behaviour`,
            envKey: `CartHasVariant('$value')`,
            options: resources.mainAttributeOptions.map((o) => ({
              key: o,
              value: o,
            })),
          },
        ]
      : []),
    ...(enhancedCartTargetingEnabled
      ? [
          {
            kind: QBType.MULTI_VALUE,
            caption: `Cart Item Has Tag`,
            group: `Behaviour`,
            options: resources?.tags?.map(
              (tag) =>
                ({
                  key: tag,
                  value: tag,
                } as SelectOptions),
            ),
            envKey: `CartItemHasTag('$value')`,
          },
          {
            kind: QBType.MULTI_VALUE,
            caption: `All Cart Items Has Tag`,
            group: `Behaviour`,
            options: resources?.tags?.map(
              (tag) =>
                ({
                  key: tag,
                  value: tag,
                } as SelectOptions),
            ),
            envKey: `AllCartItemsHasTag('$value')`,
          },
          {
            kind: QBType.MULTI_VALUE,
            caption: `Cart Item In Collection`,
            group: `Behaviour`,
            options: resources?.collections?.map(
              (col) =>
                ({
                  key: col.handle,
                  value: col.title,
                } as SelectOptions),
            ),
            envKey: `CartItemHasCollection('$value')`,
          },
        ]
      : []),
    ...(subsEnabled
      ? [
          {
            kind: QBType.TEXT_VALUE,
            caption: `Subscription In Cart`,
            group: `Behaviour`,
            envKey: `CartHasSubs('$value','$op')`,
          },
          {
            kind: QBType.NUMERIC_VALUE,
            caption: `Number of subscription items in the cart`,
            group: `Behaviour`,
            envKey: `CartSubs().length $op $value`,
          },
          {
            kind: QBType.MULTI_VALUE,
            caption: `Odd/Even Number of Subscriptions in Cart`,
            group: `Behaviour`,
            disableOp: true,
            envKey: `CartSubs().length % 2 == ('$value' == 'even' ? 0:1)`,
            options: [
              {
                key: `odd`,
                value: `odd`,
              },
              {
                key: `even`,
                value: `even`,
              },
            ],
          },
        ]
      : []),
  ];

  const loyalty: QBItemProp[] = [
    {
      kind: QBType.MULTI_VALUE,
      caption: `Loyalty Membership`,
      envKey: `IsLoyaltyCustomer()`,
      group: `Loyalty`,
      options: [{ key: `true`, value: `Enabled` }],
    },
    {
      kind: QBType.NUMERIC_VALUE,
      caption: `Loyalty Points Balance`,
      envKey: `LoyaltyPointsBalance() $op $value`,
      group: `Loyalty`,
      defaultNumericValueOp: NumberValueOps.GT,
    },
    {
      kind: QBType.NUMERIC_VALUE,
      caption: `Loyalty Points Earned`,
      envKey: `LoyaltyPointsBalance() $op $value`,
      group: `Loyalty`,
      defaultNumericValueOp: NumberValueOps.GT,
    },
  ];

  const products: QBItemProp[] = [
    {
      kind: QBType.NUMERIC_VALUE,
      caption: `# of Products Seen`,
      group: `Behaviour`,
      hasTimeframe: true,
      envKey: `len(ProductsSeenIn($timeframe)) $op $value`,
      defaultNumericValueOp: NumberValueOps.GT,
    },
    {
      kind: QBType.MULTI_VALUE,
      caption: `Products Seen`,
      hasTimeframe: true,
      group: `Behaviour`,
      envKey: `len(filter(ProductsSeenIn($timeframe), {.Handle $op "$value"})) > 0`,
      options: productsOptions,
    },
  ];

  const visitInPageAudience = {
    kind: QBType.MULTI_VALUE,
    caption: `Visit In Page`,
    hasTimeframe: true,
    canCreateOption: true,
    alsoApplyOnQueryParams: visitInPageClientSideEnabled,
    group: `Behaviour`,
    options: [
      { key: `${account.store.domain}`, value: `Homepage` },
      { key: `${account.store.domain}/cart`, value: `Cart` },
      { key: `${account.store.domain}/checkout`, value: `Checkout` },
      ...productsOptions.map(({ key, value }) => ({
        key: `${account.store.domain}/products/${key}`,
        value: `${value} (Product)`,
      })),
      ...collections.map(({ key, value }) => ({
        key: `${account.store.domain}/collections/${key}`,
        value: `${value} (Collection)`,
      })),
    ],
    envKey: visitInPageClientSideEnabled
      ? `PageVisit('$value','$timeframe','$op','$qp')`
      : `any(PagesVisited($timeframe), {.Name $op "$value"})`,
  } as QBItemProp;
  const orders: QBItemProp[] = [
    {
      kind: QBType.NUMERIC_VALUE,
      caption: `# of Orders`,
      hasTimeframe: true,
      group: `Behaviour`,
      envKey: `len(OrdersIn($timeframe)) $op $value`,
      defaultNumericValueOp: NumberValueOps.GT,
    },
    {
      kind: QBType.MULTI_VALUE,
      caption: `Purchased Items`,
      hasTimeframe: true,
      group: `Behaviour`,
      envKey: `any(OrdersIn($timeframe), {len(filter(.Products, { .Handle $op "$value" })) == 1})`,
      options: productsOptions,
    },
    ...(variantAttrEnabled && resources.mainAttributeOptions.length > 0
      ? [
          {
            kind: QBType.MULTI_VALUE,
            caption: `Purchased Variants`,
            group: `Behaviour`,
            envKey: `PurchasedVariants('$value')`,
            options: resources.mainAttributeOptions.map((o) => ({
              key: o,
              value: o,
            })),
          },
        ]
      : []),
    visitInPageAudience,
  ];

  const marketing: QBItemProp[] = [
    {
      kind: QBType.TEXT_VALUE,
      caption: `UTM Source`,
      hasTimeframe: true,
      group: `Marketing`,
      envKey: `any(TrafficSourcesIn($timeframe), {.Name $op Lower("$value")})`,
    },
    {
      kind: QBType.TEXT_VALUE,
      caption: `UTM Medium`,
      group: `Marketing`,
      hasTimeframe: true,
      envKey: `any(TrafficMediumIn($timeframe), {.Name $op Lower("$value")})`,
    },
    {
      kind: QBType.TEXT_VALUE,
      caption: `UTM Campaign`,
      group: `Marketing`,
      hasTimeframe: true,
      envKey: `any(TrafficCampaignsIn($timeframe), {.Name $op Lower("$value")})`,
    },
    {
      kind: QBType.TEXT_VALUE,
      caption: `UTM Content`,
      group: `Marketing`,
      hasTimeframe: true,
      envKey: `any(TrafficContentIn($timeframe), {.Name $op Lower("$value")})`,
    },
    {
      kind: QBType.TEXT_VALUE,
      caption: `UTM Term`,
      group: `Marketing`,
      hasTimeframe: true,
      envKey: `any(TrafficTermIn($timeframe), {.Name $op Lower("$value")})`,
    },
  ];

  const country: QBItemProp = {
    kind: QBType.MULTI_VALUE,
    caption: `Country`,
    group: `Geo`,
    options: countryCodes.map((cc) => ({ key: cc.key, value: cc.name })),
    envKey: `Country $op "$value"`,
  };

  const state: QBItemProp = {
    kind: QBType.MULTI_VALUE,
    group: `Geo`,
    caption: `State`,
    options: stateCodes,

    envKey: `StateMatches("$value")`,
  };

  const city: QBItemProp = {
    kind: QBType.TEXT_VALUE,
    caption: `City`,
    group: `Geo`,
    envKey: `City $op "$value"`,
  };
  const currency: QBItemProp = {
    kind: QBType.MULTI_VALUE,
    caption: `Cart Currency`,
    group: `Geo`,
    options: Object.keys(currencies).map((key) => ({ key, value: key })),
    envKey: `CartCurrency $op "$value"`,
  };

  const geo = [country, state, city, currency];

  let audienceProps = [
    predefinedSegments,
    device,
    os,
    browser,
    brand,
    ...cart,
    ...products,
    ...orders,
    ...marketing,
    ...geo,
  ];
  if (resources?.customerTags?.length > 0) {
    audienceProps = [
      ...audienceProps,
      {
        kind: QBType.ARRAY_VALUE,
        caption: `Customer Tags`,
        group: `Other`,
        options: resources?.customerTags?.map((value) => ({
          key: value,
          value,
        })),
        envKey: `CustomerTag('$value','$op')`,
      },
    ];
  }

  if (isLoyaltyEnabled) {
    audienceProps.push(...loyalty);
  }

  return { audienceProps };
}
