export const usualAttributes = {
  align: `center`,
  font: null,
  color: `#111111`,
  size: `24px`,
  letterSpacing: `0px`,
  italic: false,
  fontWeight: `500`,
  strike: false,
  underline: false,
  transform: null,
  lineHeight: `unset`,
  direction: `ltr`,
  bold: false,
  textShadow: `unset`,
  background: false,
};

export const fontFamilyOptions = [
  `Helvetica`,
  `Roboto`,
  `Open Sans`,
  `Fredoka`,
  `Smooch Sans`,
  `Rubik`,
  `Lato`,
  `Poppins`,
  `Oswald`,
  `League Spartan`,
  `Noto Sans`,
  `Raleway`,
  `Merriweather`,
  `Playfair Display`,
  `Inter`,
  `Jetbrains Mono`,
];
