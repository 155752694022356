import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import 'react-quill/dist/quill.snow.css';
import './rich-text-css-settings.css';
import {
  CustomToolbarRichText,
  modules,
} from '@/features/editor/widgets/custom-widget/inputs/typography/custom-toolbar-rich-text';
import { EditorContext } from '@/features/editor/context/editor-context';
import { DeviceType } from '@/utils/definitions';
import { HistoryOpts } from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';
import { maybe } from '@/features/details/utils';
import { usualAttributes, fontFamilyOptions } from '@/utils/rich-text-utils';

const VALUE_KEY = `defaultAttributes`;

const ReactQuill =
  typeof window === `object` ? require(`react-quill`) : () => false;
export interface RichTextEditorProps {
  defaultValue: string;
  onChange: (ev: any) => void;
  ref: any;
  spec: any;
  onValuesChanged: (
    key: string,
    value: any,
    device?: DeviceType,
    historyOpts?: HistoryOpts,
  ) => void;
}
export function RichTextEditor({
  defaultValue,
  ref,
  spec,
  onValuesChanged,
  onChange,
}: RichTextEditorProps) {
  const [updatedFonts, setUpdatedFonts] = useState([]);
  const [isRendered, setIsRendered] = useState(false);
  const [colorValue, setColorValue] = useState(usualAttributes.color);
  const [fontSizeValue, setFontSizeValue] = useState(usualAttributes.size);
  const [letterSpacingValue, setLetterSpacingValue] = useState(
    usualAttributes.letterSpacing,
  );
  const [lineHeightValue, setLineHeightValue] = useState(
    usualAttributes.lineHeight,
  );
  const [textShadowValue, setTextShadowValue] = useState(
    usualAttributes.textShadow,
  );
  const [textLength, setTextLength] = useState(0);
  const editorRef = useRef(null);

  const {
    devicePreview: {
      editorState: { installedFonts, device: previewDevice },
    },
  } = useContext(EditorContext);

  useEffect(() => {
    const defaultFonts = fontFamilyOptions.filter(
      (font) =>
        !installedFonts?.some(
          (option) => option.toLowerCase() === font.toLowerCase(),
        ),
    );
    const updated = [...installedFonts, ...defaultFonts];
    setUpdatedFonts(updated);
  }, [installedFonts]);

  useEffect(() => {
    if (editorRef.current) {
      const quill = editorRef.current.getEditor();
      const clearBackgroundColor = () => {
        const length = quill.getLength();
        quill.formatText(0, length, { background: false });
      };
      const handleSelectionChange = (range) => {
        if (
          !!range &&
          checkRangeValue(range.index) &&
          checkRangeValue(range.length)
        ) {
          clearBackgroundColor();
          if (range.length > 0) quill.format(`background`, `#B4D7FE`);
          const format = quill.getFormat(
            range.length > 0 ? range.index + 1 : range.index,
          );
          const letterSpacing =
            format.letterSpacing || usualAttributes.letterSpacing;
          const fontSize = format.size || usualAttributes.size;
          const color = format.color || usualAttributes.color;
          const lineHeight = format.lineHeight || usualAttributes.lineHeight;
          const textShadow = format.textShadow || usualAttributes.textShadow;
          setLineHeightValue(lineHeight);
          setLetterSpacingValue(letterSpacing);
          setFontSizeValue(fontSize);
          setColorValue(color);
          setTextShadowValue(textShadow);
        }
      };
      const delayedSelectionChange = (range: any) => {
        setTimeout(() => handleSelectionChange(range), 100);
      };
      quill.on(`selection-change`, delayedSelectionChange);

      return () => {
        clearBackgroundColor();
        quill?.off(`selection-change`, delayedSelectionChange);
      };
    }
    return null;
  }, [isRendered]);

  useEffect(() => {
    const styleElement = document.createElement(`style`);
    document.head.appendChild(styleElement);
    updatedFonts.forEach((font) => {
      styleElement.sheet.insertRule(
        `#toolbar .ql-font span[data-label='${font}']::before { font-family: ${font}; }`,
        styleElement.sheet.cssRules.length,
      );
    });
    if (editorRef?.current) {
      const editor = editorRef.current.getEditor();
      if (editor) {
        const delta = editor.getContents();
        const attributes =
          (previewDevice === DeviceType.Desktop
            ? maybe(() => spec?.values?.desktop?.defaultAttributes) ||
              maybe(() => spec?.values?.defaultAttributes)
            : maybe(() => spec?.values?.defaultAttributes)) || usualAttributes;
        const firstOp = delta.ops.find(
          (el: { insert: string }) =>
            !!el.insert && el.insert.trim().length > 0,
        );
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { bold, background, ...restAttributes } =
          firstOp?.attributes || {};

        if (!!firstOp && !areSpecificKeysEqual(restAttributes, attributes)) {
          const length = editor.getLength();
          editor.formatText(0, length, {
            ...attributes,
            align: attributes.align || null,
          });
        }
        if (!!updatedFonts[0] && !attributes.font) {
          editor.format(`font`, updatedFonts[0]);
        }
      }
    }

    return () => {
      document.head.removeChild(styleElement);
    };
  }, [isRendered, updatedFonts]);

  function onQuillChange(val) {
    onChange(encodeURIComponent(val));
  }

  useEffect(() => {
    if (editorRef?.current) {
      const editor = editorRef.current.getEditor();
      if (editor) {
        const length = editor.getLength();
        if (length !== textLength) {
          editor.formatText(0, length, { background: false });
        }
        setTextLength(length);
        const delta = editor.getContents();
        let firstOp = null;
        let index = 0;
        delta.ops.forEach((op: { insert: string | any }) => {
          if (!firstOp) {
            if (!!op.insert && op.insert?.trim().length > 0) {
              firstOp = op;
              return;
            }
            index +=
              typeof op.insert === `string` && op.insert.length > 0
                ? op.insert.length
                : 1;
          }
        });
        const inlineFormat = editor.getFormat(index, index + 1);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { background, ...restInlineFormat } = inlineFormat;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { align, bold, ...restUsualAttributes } = usualAttributes;
        const firstFormat = firstOp
          ? {
              ...restUsualAttributes,
              ...restInlineFormat,
            }
          : { ...usualAttributes };

        onValuesChanged(VALUE_KEY, firstFormat);

        const attributes = usualAttributes;
        if (delta.ops.length === 1) {
          delta.ops.forEach((op) => {
            if (op.insert.trim() !== ``) return;
            op.attributes = {
              ...attributes,
              ...op.attributes,
            };
            Object.keys(attributes).forEach((key) => {
              const value = attributes[key];
              editor.format(key, value);
            });
          });
        }
        const range = editor.getSelection();
        const format = range ? editor.getFormat() : usualAttributes;
        const letterSpacing =
          format.letterSpacing || usualAttributes.letterSpacing;
        const fontSize = format.size || usualAttributes.size;
        const color = format.color || usualAttributes.color;
        const lineHeight = format.lineHeight || usualAttributes.lineHeight;
        const textShadow = format.textShadow || usualAttributes.textShadow;
        setLineHeightValue(lineHeight);
        setLetterSpacingValue(letterSpacing);
        setFontSizeValue(fontSize);
        setColorValue(color);
        setTextShadowValue(textShadow);
      }
    }
  }, [defaultValue]);
  return (
    updatedFonts.length > 0 && (
      <Wrapper ref={ref} className="text-editor">
        <CustomToolbarRichText
          isRendered={isRendered}
          setIsRendered={setIsRendered}
          updatedFonts={updatedFonts}
          setColorValue={setColorValue}
          colorValue={colorValue}
          fontSizeValue={fontSizeValue}
          setFontSizeValue={setFontSizeValue}
          letterSpacingValue={letterSpacingValue}
          setLetterSpacingValue={setLetterSpacingValue}
          textShadowValue={textShadowValue}
          lineHeightValue={lineHeightValue}
          setLineHeightValue={setLineHeightValue}
          editorRef={editorRef}
          spec={spec}
        />
        {isRendered && (
          <ReactQuill
            value={decodeURIComponent(defaultValue)}
            ref={editorRef}
            onChange={(val: string) => onQuillChange(val)}
            modules={modules}
            placeholder="Change me"
          />
        )}
      </Wrapper>
    )
  );
}

const Wrapper = styled.div`
  background: white;
  margin-bottom: 2rem;
  border-radius: 10px !important;
`;

function checkRangeValue(v) {
  return typeof v === `number` && v >= 0;
}

function areSpecificKeysEqual(obj1, obj2) {
  const keys = Object.keys(obj1);
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }
  return true;
}
